import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { AutoplayOptions, SwiperOptions } from 'swiper/types';
import { defaultUserPhotoBase64 } from './default-photo';

export function utilConstants(): string {
  return 'util-constants';
}

export const baseRoutePathTypes = {
  Login: 'login',
  SignUp: 'signup',
  Profile: 'profile',
  Welcome: 'welcome',
  InteractiveCV: 'cv',
  LiveInterview: 'live',
  VirtualInterview: 'virtual',
  Search: 'search',
  YourSchedule: 'my',
  Options: 'option',
  QA: 'qa',
  QAId: 'qa/:id',
  NotFound: '404',
  Settings: 'settings',
  ForgetPassword: 'forgetpassword',
  RecoveryEmail: 'recoveryemailverify/:email',
};

export const passwordPattern =
  '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$';

// export const emailPattern =
//   '^\\w+([\\.-]?\\w{2,50})*@\\w+([\\.-]?\\w{2,50})*(\\.\\w{2,5})+$';

export const emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

export const passwordPolicy = `Password Policy:- at least 8 characters (12+ recommended), at least one uppercase letter, at least one lowercase letter, at least one number, at least one special character (!”#$%&'()*+,-./:;<=>?).`;

export const defaultDialCode = {
  name: 'United States',
  dialCode: '+1',
  code: 'US',
};

export const defaultTimeZone = {
  country: 'United States',
  iso: 'US',
  timeZone: 'Pacific Standard Time',
  utc: '(UTC-08:00)',
  description: 'Pacific Time (US & Canada)',
  id: '',
};

export const snackbarINFO: MatSnackBarConfig = {
  verticalPosition: 'top',
  horizontalPosition: 'right',
  panelClass: 'snackbar-info',
};

export const snackbarERROR: MatSnackBarConfig = {
  verticalPosition: 'top',
  horizontalPosition: 'right',
  panelClass: 'snackbar-error',
};

export const snackbarSUCCESS: MatSnackBarConfig = {
  verticalPosition: 'top',
  horizontalPosition: 'right',
  panelClass: 'snackbar-success',
};
export const adminRoles: string[] = ['admin'];
export const phoneCodeAPI = 'https://countrycode.dev/api/calls';
export const globalRoles: string[] = ['Admin', 'User', 'Super User'];

export const swiperOptions: SwiperOptions = {
  slidesPerView: 2,
  spaceBetween: 30,
  centeredSlides: false,
  pagination: {
    clickable: true,
  },
  grabCursor: true,
  loop: true,
  autoplay: {
    delay: 1000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  } as AutoplayOptions,
  navigation: {
    nextEl: '.next-button',
    prevEl: '.swiper-button-hidden',
  },
  mousewheel: {
    releaseOnEdges: true,
  },
  direction: 'horizontal',
};

export const headerRouterLinks = [
  // {
  //   name: 'Curriculum vitae',
  //   icon: 'fa-regular fa-file-lines',
  //   routeLink: '/profile',
  // },
  {
    name: 'Interactive CV',
    icon: 'fa-solid fa-video',
    routeLink: '/cv',
  },
  {
    name: 'Virtual Interview',
    icon: 'fa-solid fa-display ',
    routeLink: '/virtual',
  },
  {
    name: 'Live Interview',
    icon: 'fa-solid fa-record-vinyl',
    routeLink: '/live',
  },
  // {
  //   name: 'My Schedule',
  //   icon: 'fa-solid fa-user-plus',
  //   routeLink: '/my',
  // },
];
export const profileMenuRouterLinks = [
  {
    name: 'Curriculum vitae',
    icon: 'fa-regular fa-file-lines',
    routeLink: '/profile/',
  },
  {
    name: 'Interactive CV',
    icon: 'fa-solid fa-video',
    routeLink: '/cv/',
  },
  // {
  //   name: 'Virtual Interview',
  //   icon: 'fa-solid fa-display ',
  //   routeLink: '/virtual/view/',
  // },
  // {
  //   name: 'Live Interview',
  //   icon: 'fa-solid fa-record-vinyl',
  //   routeLink: '/live/hr/',
  // },
];

export const alphabeticPattern = '^[A-Za-z]+$';

export const timeSeries: string[] = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',

  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
];

export const amPm = ['AM', 'PM'];
export const reminder = ['5 minutes', '15 minutes', '30 minutes', '1 hour'];

export const defaultUserPhoto =
  'data:image/jpeg;base64,' + defaultUserPhotoBase64;

export const Skills: string[] = [
  'Angular Cli',
  'Angular Material',
  'Python',
  'C++',
  'C#',
  'Java',
  'JavaScript',
  'PHP',
  'Perl',
  '.Net',
  '.Net Core',
  '.Net Compact Framework',
  'React',
  'React Native',
  'CSS',
  'Html',
  'TypeScript',
  'F#',
  'Visual basic',
  'Node js',
  'Ruby on Rails',
  'Apache',
  'MySQL',
  'SAS',
  'Machine learning',
  'Data mining',
  'SQLite',
  'MS Access',
  'Oracle',
  'Teradata',
  'SQL',
  'SAP',
  'Power BI',
  'BCP',
  'CRM',
  'E-commerce',
  'HR management',
  'ERP',
  'Quikbooks',
  ' FreshBooks',
  'Xero',
  'Business intelligence',
  'Bussiness Development',
  'Dreamweaver',
  'Illustrator',
  'InDesign',
  'Lightroom',
  'Photoshop',
  'Acrobat',
  'Corel Draw',
  'AutoCAD',
  'Microsoft Publisher',
  'Figma',
  'Microsoft Excel',
  'Microsoft PowePoint',
  'Microsoft Sharepoint',
  'Microsoft word',
  'Hardware configuration',
  'System Administrator',
  'tech support',
  'Software installation',
  'Linux/Unix',
  'Network configuration',
  'security',
  'network automation',
  'cloud management',
  'WAN/LAN',
  'DNS',
  'DHCP',
  'debugging',
  'Splunk',
  'Transact-Sql',
  'Rxjs',
  'Python Turtle',
  'Keras',
  'Artificial Intelligence',
  'Amazon Web Services',
  'Selenium',
  'Cloud Computing',
  'Hadoop',
  'Data Science',
  'BlockChain',
  'DevOps',
  'Comptative Programming',
  'DBMS',
  'Data Structures',
  'Cyber Security',
  'Data MIning',
  'Data Warehouse',
  'Cloud Computing',
  'Microsoft Azure',
  'Salesforce',
  'Google Cloud',
  'Agile Methodology',
  'JIRA',
  'ETL',
  'JMeter',
  'Postman',
  'JUnit',
  'SoapUi',
  'Django',
  'Flask',
  'NumPy',
  'Tkinter',
  'Pytorch',
  'Pandas',
  'Jupyter',
  'Servlet',
  'JSP',
  'Spring Boot',
  'Spring FrameWork',
  'Hibernate',
  'Java Web Services',
  'Struts',
  'RESTful Web Services',
  'Microservices',
  'Java Maven',
  'JDBC',
  'PostgreSQL',
  'SQL Server',
  'CouchDB',
  'Jquery',
  'XML',
  'JSON',
  'Ajax',
  'Web Services',
  'Bootstrap',
  'ExpressJS',
  'Sass',
  'Laravel',
  'Wordpress',
  'Xampp',
  'Microsoft Powershell',
  'ASP.NET',
  'ADO.NET',
  'Web Api',
  'LINQ',
  'Android',
  'Linux',
  'SEO',
  'Docker',
  'Go Lang',
  'Ubuntu',
  'Kotlin',
  'Swift',
  'Spark',
  'Tableau',
  'Nginx',
  'Bash',
  'Git',
  'Xamrin',
  'Ionic',
  'Github',
  'Unity',
  'Kali linux',
  'Firebase',
  'Cobol',
  'Flutter',
  'Tally',
  'Kubernetes',
  'GraphQL',
  'ioT',
  'Vue.js',
  'illustrator',
  'Dart',
  'Digital Marketing',
  'Entity Marketing',
  'Functional Programming',
  'Mobile Computing',
  'Mobile Testing',
  'Marketing',
  'Problem-Solving',
];

export const universityStudys = [
  'B.Tech/B.E',
  'B.Com',
  'B.Sc',
  'B.A',
  'B.Ed',
  'B.B.A/B.M.S',
  'B.Pharma',
  'BCA',
  'BHMS',
  'LLB',
  'MBBS',
  'BDS',
  'MBA/PGDM',
  'M.Tech',
  'MS/M.Sc(Science)',
  'MCA',
  'M.Com',
  'PG Diploma',
  'M.A',
  'CA',
  'CS',
  'DM',
  'M.ED',
  'M.Pharma',
  'MDS',
];
export const companyNames = [
  'Tata Consultancy Services (TCS)',
  'Infosys',
  'Wipro',
  'HCL Technologies',
  'Tech Mahindra',
  'Cognizant',
  'Mindtree',
  'Capgemini',
  'Larsen & Toubro Infotech (LTI)',
  'Mphasis',
  'Persistent Systems',
  'NIIT Technologies',
  'InoCrypt Infosoft',
  'Solmeyvn Software Development Company',
  'Retinodes Software Solutions ',
  'Systematix Infotech',
  'Conative IT Solutions',
  'Systango',
  'Diginfoexpert',
  'Retinodes Software Solutions ',
  'Pageup Software Services',
  'Kreyon Systems',
  'Taritas Software Solutions',
  'Codeholic Pvt Ltd',
  'Rectitude Consultancy Services Pvt ltd',
  'Hiteshi Infotech Pvt Ltd',
  'Ignis It Solutions ',
  'Parkhya Solution Pvt Ltd',
  'Logimonk pvt ltd',
  'Yash Technologies',
  'Mango It Solutions Ubber',
  'Covetus',
];
export const schoolStudys = [
  'Computer Science',
  'Maths-Science',
  'Maths-Biology',
  'Biology',
  'Commerce',
  'Maths-Commerce',
  'Computer-Commerce',
  'Humanities/Arts',
  'IP',
];
